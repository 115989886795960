import { io } from 'socket.io-client'
import { useLoggedUserStore } from '@/stores/loggedUser'
import { useAppStore } from '@/stores/app'
import { useExamStore } from '@/stores/exam'
import { useAuth } from '@/auth/useAuth'
export const SOCKET_KEY = Symbol('socket')

export default {
  install(app, { connection, options, pinia }) {
    const user = JSON.parse(localStorage.getItem('loggedUser'))?.item

    const socket = io(connection, options)

    socket.on('connect', () => {
      socket.emit('authentication', {
        token: user ? user.token : 'guest',
      })
    })

    socket.on('users', (data) => {
      const appStore = useAppStore(pinia)
      appStore.onlineUsers = data.users
    })

    socket.on('authenticated', (authType) => {
      const appStore = useAppStore(pinia)
      appStore.connected = true
      if (authType === 'user' && user) {
        socket.emit('updateuser', {
          logid: user.logid,
          url: window.location.pathname,
        })
      }
    })

    socket.on('reload', () => {
      const appStore = useAppStore(pinia)
      if (!appStore.preventDefault) {
        location.reload()
      }
    })

    socket.on('logout', () => {
      const appStore = useAppStore(pinia)
      const auth = useAuth()
      if (!appStore.preventDefault) {
        socket.disconnect()
        appStore.connected = false
      }
    })

    socket.on('disconnect', () => {
      const appStore = useAppStore(pinia)
      if (!appStore.preventDefault) {
        socket.disconnect()
        const auth = useAuth()
        appStore.connected = false
      }
    })

    socket.on('urgency-alert', async (data) => {
      const loggedUserStore = useLoggedUserStore(pinia)
      const examStore = useExamStore(pinia)
      examStore.getNoCheckedAlerts({ sourceId: loggedUserStore.currentSource })
    })

    socket.on('duplicated', (data) => {
      const appStore = useAppStore(pinia)
      appStore.singleSession = false
    })

    socket.on('single', () => {
      const appStore = useAppStore(pinia)
      appStore.singleSession = true
    })

    socket.on('newexam', async (data) => {
      const refreshButton = document.querySelector('button[ref="refreshButton"]')
      if (refreshButton) {
        refreshButton.click()
      }
    })

    app.config.globalProperties.$socket = socket
    app.provide(SOCKET_KEY, socket)
  },
}
