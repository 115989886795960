/* tslint:disable */
/* eslint-disable */
/**
 * Portal Telemedicina API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { ExamViewModel } from '../models';
// @ts-ignore
import { SpecialityViewModel } from '../models';
/**
 * PreOrderApi - axios parameter creator
 * @export
 */
export const PreOrderApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPtmsyncActivationCodeCreatePost: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ptmsync/ActivationCode/Create`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} preOrderId 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPtmsyncPreOrderDetailsGet: async (preOrderId: number, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'preOrderId' is not null or undefined
            assertParamExists('apiPtmsyncPreOrderDetailsGet', 'preOrderId', preOrderId)
            const localVarPath = `/api/ptmsync/PreOrder/Details`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (preOrderId !== undefined) {
                localVarQueryParameter['preOrderId'] = preOrderId;
            }

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {ExamViewModel} examViewModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPtmsyncPreOrderPut: async (examViewModel: ExamViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'examViewModel' is not null or undefined
            assertParamExists('apiPtmsyncPreOrderPut', 'examViewModel', examViewModel)
            const localVarPath = `/api/ptmsync/PreOrder/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(examViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPtmsyncPreOrderStatusGet: async (xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/ptmsync/PreOrder/Status`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PreOrderApi - functional programming interface
 * @export
 */
export const PreOrderApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PreOrderApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPtmsyncActivationCodeCreatePost(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPtmsyncActivationCodeCreatePost(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} preOrderId 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPtmsyncPreOrderDetailsGet(preOrderId: number, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SpecialityViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPtmsyncPreOrderDetailsGet(preOrderId, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {ExamViewModel} examViewModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPtmsyncPreOrderPut(examViewModel: ExamViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPtmsyncPreOrderPut(examViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiPtmsyncPreOrderStatusGet(xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SpecialityViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiPtmsyncPreOrderStatusGet(xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * PreOrderApi - factory interface
 * @export
 */
export const PreOrderApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PreOrderApiFp(configuration)
    return {
        /**
         * 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPtmsyncActivationCodeCreatePost(options?: any): AxiosPromise<void> {
            return localVarFp.apiPtmsyncActivationCodeCreatePost(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} preOrderId 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPtmsyncPreOrderDetailsGet(preOrderId: number, xAuth?: string, options?: any): AxiosPromise<Array<SpecialityViewModel>> {
            return localVarFp.apiPtmsyncPreOrderDetailsGet(preOrderId, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {ExamViewModel} examViewModel 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPtmsyncPreOrderPut(examViewModel: ExamViewModel, options?: any): AxiosPromise<void> {
            return localVarFp.apiPtmsyncPreOrderPut(examViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiPtmsyncPreOrderStatusGet(xAuth?: string, options?: any): AxiosPromise<Array<SpecialityViewModel>> {
            return localVarFp.apiPtmsyncPreOrderStatusGet(xAuth, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiPtmsyncPreOrderDetailsGet operation in PreOrderApi.
 * @export
 * @interface PreOrderApiApiPtmsyncPreOrderDetailsGetRequest
 */
export interface PreOrderApiApiPtmsyncPreOrderDetailsGetRequest {
    /**
     * 
     * @type {number}
     * @memberof PreOrderApiApiPtmsyncPreOrderDetailsGet
     */
    readonly preOrderId: number

    /**
     * 
     * @type {string}
     * @memberof PreOrderApiApiPtmsyncPreOrderDetailsGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for apiPtmsyncPreOrderPut operation in PreOrderApi.
 * @export
 * @interface PreOrderApiApiPtmsyncPreOrderPutRequest
 */
export interface PreOrderApiApiPtmsyncPreOrderPutRequest {
    /**
     * 
     * @type {ExamViewModel}
     * @memberof PreOrderApiApiPtmsyncPreOrderPut
     */
    readonly examViewModel: ExamViewModel
}

/**
 * Request parameters for apiPtmsyncPreOrderStatusGet operation in PreOrderApi.
 * @export
 * @interface PreOrderApiApiPtmsyncPreOrderStatusGetRequest
 */
export interface PreOrderApiApiPtmsyncPreOrderStatusGetRequest {
    /**
     * 
     * @type {string}
     * @memberof PreOrderApiApiPtmsyncPreOrderStatusGet
     */
    readonly xAuth?: string
}

/**
 * PreOrderApi - object-oriented interface
 * @export
 * @class PreOrderApi
 * @extends {BaseAPI}
 */
export class PreOrderApi extends BaseAPI {
    /**
     * 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreOrderApi
     */
    public apiPtmsyncActivationCodeCreatePost(options?: AxiosRequestConfig) {
        return PreOrderApiFp(this.configuration).apiPtmsyncActivationCodeCreatePost(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PreOrderApiApiPtmsyncPreOrderDetailsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreOrderApi
     */
    public apiPtmsyncPreOrderDetailsGet(requestParameters: PreOrderApiApiPtmsyncPreOrderDetailsGetRequest, options?: AxiosRequestConfig) {
        return PreOrderApiFp(this.configuration).apiPtmsyncPreOrderDetailsGet(requestParameters.preOrderId, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PreOrderApiApiPtmsyncPreOrderPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreOrderApi
     */
    public apiPtmsyncPreOrderPut(requestParameters: PreOrderApiApiPtmsyncPreOrderPutRequest, options?: AxiosRequestConfig) {
        return PreOrderApiFp(this.configuration).apiPtmsyncPreOrderPut(requestParameters.examViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {PreOrderApiApiPtmsyncPreOrderStatusGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PreOrderApi
     */
    public apiPtmsyncPreOrderStatusGet(requestParameters: PreOrderApiApiPtmsyncPreOrderStatusGetRequest = {}, options?: AxiosRequestConfig) {
        return PreOrderApiFp(this.configuration).apiPtmsyncPreOrderStatusGet(requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }
}
