/* tslint:disable */
/* eslint-disable */
/**
 * Portal Telemedicina API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { DisplayOptionsViewModel } from '../models';
// @ts-ignore
import { ExamSourceInternalSystemhViewModel } from '../models';
// @ts-ignore
import { FinantialAlertViewModel } from '../models';
// @ts-ignore
import { InlineObject17 } from '../models';
// @ts-ignore
import { MonthlyReportJobViewModel } from '../models';
// @ts-ignore
import { Notification } from '../models';
// @ts-ignore
import { SubscriptionViewModel } from '../models';
/**
 * ExamSourceApi - axios parameter creator
 * @export
 */
export const ExamSourceApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorExamSourceIdentificationTypeGet: async (xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Administrator/ExamSource/IdentificationType`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {ExamSourceInternalSystemhViewModel} [examSourceInternalSystemhViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorExamSourceInternalSystemPut: async (xAuth?: string, examSourceInternalSystemhViewModel?: ExamSourceInternalSystemhViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Administrator/ExamSource/InternalSystem`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(examSourceInternalSystemhViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorExamSourceRefreshLastExamPost: async (xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/Administrator/ExamSource/RefreshLastExam`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreAdministratorExamSourceIdGet: async (id: number, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('apiCoreAdministratorExamSourceIdGet', 'id', id)
            const localVarPath = `/api/core/Administrator/ExamSource/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject17} inlineObject17 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreAdministratorExamSourcePost: async (inlineObject17: InlineObject17, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject17' is not null or undefined
            assertParamExists('apiCoreAdministratorExamSourcePost', 'inlineObject17', inlineObject17)
            const localVarPath = `/api/core/Administrator/ExamSource`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject17, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {boolean} [occupational] 
         * @param {string} [modifiedStartDate] 
         * @param {string} [modifiedEndDate] 
         * @param {boolean} [isBlocked] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderColumn] 
         * @param {string} [orderBy] 
         * @param {boolean} [internalSystem] 
         * @param {boolean} [hasFinantialAlert] 
         * @param {Array<Notification>} [notifications] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreAdministratorExamSourceSearchGet: async (name?: string, occupational?: boolean, modifiedStartDate?: string, modifiedEndDate?: string, isBlocked?: boolean, page?: number, pageSize?: number, orderColumn?: string, orderBy?: string, internalSystem?: boolean, hasFinantialAlert?: boolean, notifications?: Array<Notification>, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/core/Administrator/ExamSource/Search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }

            if (occupational !== undefined) {
                localVarQueryParameter['Occupational'] = occupational;
            }

            if (modifiedStartDate !== undefined) {
                localVarQueryParameter['ModifiedStartDate'] = (modifiedStartDate as any instanceof Date) ?
                    (modifiedStartDate as any).toISOString() :
                    modifiedStartDate;
            }

            if (modifiedEndDate !== undefined) {
                localVarQueryParameter['ModifiedEndDate'] = (modifiedEndDate as any instanceof Date) ?
                    (modifiedEndDate as any).toISOString() :
                    modifiedEndDate;
            }

            if (isBlocked !== undefined) {
                localVarQueryParameter['IsBlocked'] = isBlocked;
            }

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (orderColumn !== undefined) {
                localVarQueryParameter['OrderColumn'] = orderColumn;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (internalSystem !== undefined) {
                localVarQueryParameter['InternalSystem'] = internalSystem;
            }

            if (hasFinantialAlert !== undefined) {
                localVarQueryParameter['HasFinantialAlert'] = hasFinantialAlert;
            }

            if (notifications) {
                localVarQueryParameter['Notifications'] = notifications;
            }

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreExamSourceExamSourceCurrentUserSourcesGet: async (xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/core/ExamSource/ExamSource/CurrentUserSources`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {boolean} [occupational] 
         * @param {string} [modifiedStartDate] 
         * @param {string} [modifiedEndDate] 
         * @param {boolean} [isBlocked] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderColumn] 
         * @param {string} [orderBy] 
         * @param {boolean} [internalSystem] 
         * @param {boolean} [hasFinantialAlert] 
         * @param {Array<Notification>} [notifications] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreExamSourceExamSourceGetAllGet: async (name?: string, occupational?: boolean, modifiedStartDate?: string, modifiedEndDate?: string, isBlocked?: boolean, page?: number, pageSize?: number, orderColumn?: string, orderBy?: string, internalSystem?: boolean, hasFinantialAlert?: boolean, notifications?: Array<Notification>, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/core/ExamSource/ExamSource/GetAll`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }

            if (occupational !== undefined) {
                localVarQueryParameter['Occupational'] = occupational;
            }

            if (modifiedStartDate !== undefined) {
                localVarQueryParameter['ModifiedStartDate'] = (modifiedStartDate as any instanceof Date) ?
                    (modifiedStartDate as any).toISOString() :
                    modifiedStartDate;
            }

            if (modifiedEndDate !== undefined) {
                localVarQueryParameter['ModifiedEndDate'] = (modifiedEndDate as any instanceof Date) ?
                    (modifiedEndDate as any).toISOString() :
                    modifiedEndDate;
            }

            if (isBlocked !== undefined) {
                localVarQueryParameter['IsBlocked'] = isBlocked;
            }

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (orderColumn !== undefined) {
                localVarQueryParameter['OrderColumn'] = orderColumn;
            }

            if (orderBy !== undefined) {
                localVarQueryParameter['OrderBy'] = orderBy;
            }

            if (internalSystem !== undefined) {
                localVarQueryParameter['InternalSystem'] = internalSystem;
            }

            if (hasFinantialAlert !== undefined) {
                localVarQueryParameter['HasFinantialAlert'] = hasFinantialAlert;
            }

            if (notifications) {
                localVarQueryParameter['Notifications'] = notifications;
            }

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} sourceId 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreExamSourceExamSourceLogoGet: async (sourceId: number, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'sourceId' is not null or undefined
            assertParamExists('apiCoreExamSourceExamSourceLogoGet', 'sourceId', sourceId)
            const localVarPath = `/api/core/ExamSource/ExamSource/Logo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (sourceId !== undefined) {
                localVarQueryParameter['sourceId'] = sourceId;
            }

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {SubscriptionViewModel} [subscriptionViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examSourceAddSubscriptionPost: async (xAuth?: string, subscriptionViewModel?: SubscriptionViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ExamSource/AddSubscription`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(subscriptionViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examSourceDisplayOptionsGet: async (xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ExamSource/DisplayOptions`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} fonteGuid 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examSourceExamSourceGetLogoExamSourceFonteGuidGet: async (fonteGuid: string, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fonteGuid' is not null or undefined
            assertParamExists('examSourceExamSourceGetLogoExamSourceFonteGuidGet', 'fonteGuid', fonteGuid)
            const localVarPath = `/ExamSource/ExamSource/GetLogoExamSource/{fonteGuid}`
                .replace(`{${"fonteGuid"}}`, encodeURIComponent(String(fonteGuid)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examSourceExamSourceLogoGet: async (xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ExamSource/ExamSource/Logo`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examSourceFinantialAlertGet: async (xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ExamSource/FinantialAlert`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examSourceMonthlyReportFileGet: async (month?: number, year?: number, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ExamSource/MonthlyReport/File`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (month !== undefined) {
                localVarQueryParameter['month'] = month;
            }

            if (year !== undefined) {
                localVarQueryParameter['year'] = year;
            }

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examSourceMonthlyReportGet: async (month?: number, year?: number, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ExamSource/MonthlyReport`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (month !== undefined) {
                localVarQueryParameter['Month'] = month;
            }

            if (year !== undefined) {
                localVarQueryParameter['Year'] = year;
            }

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {MonthlyReportJobViewModel} [monthlyReportJobViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examSourceMonthlyReportStartJobPost: async (xAuth?: string, monthlyReportJobViewModel?: MonthlyReportJobViewModel, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/ExamSource/MonthlyReport/StartJob`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json-patch+json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(monthlyReportJobViewModel, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ExamSourceApi - functional programming interface
 * @export
 */
export const ExamSourceApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ExamSourceApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async administratorExamSourceIdentificationTypeGet(xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.administratorExamSourceIdentificationTypeGet(xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {ExamSourceInternalSystemhViewModel} [examSourceInternalSystemhViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async administratorExamSourceInternalSystemPut(xAuth?: string, examSourceInternalSystemhViewModel?: ExamSourceInternalSystemhViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.administratorExamSourceInternalSystemPut(xAuth, examSourceInternalSystemhViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async administratorExamSourceRefreshLastExamPost(xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.administratorExamSourceRefreshLastExamPost(xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoreAdministratorExamSourceIdGet(id: number, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoreAdministratorExamSourceIdGet(id, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InlineObject17} inlineObject17 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoreAdministratorExamSourcePost(inlineObject17: InlineObject17, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoreAdministratorExamSourcePost(inlineObject17, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {boolean} [occupational] 
         * @param {string} [modifiedStartDate] 
         * @param {string} [modifiedEndDate] 
         * @param {boolean} [isBlocked] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderColumn] 
         * @param {string} [orderBy] 
         * @param {boolean} [internalSystem] 
         * @param {boolean} [hasFinantialAlert] 
         * @param {Array<Notification>} [notifications] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoreAdministratorExamSourceSearchGet(name?: string, occupational?: boolean, modifiedStartDate?: string, modifiedEndDate?: string, isBlocked?: boolean, page?: number, pageSize?: number, orderColumn?: string, orderBy?: string, internalSystem?: boolean, hasFinantialAlert?: boolean, notifications?: Array<Notification>, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoreAdministratorExamSourceSearchGet(name, occupational, modifiedStartDate, modifiedEndDate, isBlocked, page, pageSize, orderColumn, orderBy, internalSystem, hasFinantialAlert, notifications, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoreExamSourceExamSourceCurrentUserSourcesGet(xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoreExamSourceExamSourceCurrentUserSourcesGet(xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {boolean} [occupational] 
         * @param {string} [modifiedStartDate] 
         * @param {string} [modifiedEndDate] 
         * @param {boolean} [isBlocked] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderColumn] 
         * @param {string} [orderBy] 
         * @param {boolean} [internalSystem] 
         * @param {boolean} [hasFinantialAlert] 
         * @param {Array<Notification>} [notifications] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoreExamSourceExamSourceGetAllGet(name?: string, occupational?: boolean, modifiedStartDate?: string, modifiedEndDate?: string, isBlocked?: boolean, page?: number, pageSize?: number, orderColumn?: string, orderBy?: string, internalSystem?: boolean, hasFinantialAlert?: boolean, notifications?: Array<Notification>, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoreExamSourceExamSourceGetAllGet(name, occupational, modifiedStartDate, modifiedEndDate, isBlocked, page, pageSize, orderColumn, orderBy, internalSystem, hasFinantialAlert, notifications, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} sourceId 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoreExamSourceExamSourceLogoGet(sourceId: number, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoreExamSourceExamSourceLogoGet(sourceId, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {SubscriptionViewModel} [subscriptionViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examSourceAddSubscriptionPost(xAuth?: string, subscriptionViewModel?: SubscriptionViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examSourceAddSubscriptionPost(xAuth, subscriptionViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examSourceDisplayOptionsGet(xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DisplayOptionsViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examSourceDisplayOptionsGet(xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} fonteGuid 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examSourceExamSourceGetLogoExamSourceFonteGuidGet(fonteGuid: string, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examSourceExamSourceGetLogoExamSourceFonteGuidGet(fonteGuid, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examSourceExamSourceLogoGet(xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examSourceExamSourceLogoGet(xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examSourceFinantialAlertGet(xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FinantialAlertViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examSourceFinantialAlertGet(xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examSourceMonthlyReportFileGet(month?: number, year?: number, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examSourceMonthlyReportFileGet(month, year, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examSourceMonthlyReportGet(month?: number, year?: number, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examSourceMonthlyReportGet(month, year, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {MonthlyReportJobViewModel} [monthlyReportJobViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examSourceMonthlyReportStartJobPost(xAuth?: string, monthlyReportJobViewModel?: MonthlyReportJobViewModel, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examSourceMonthlyReportStartJobPost(xAuth, monthlyReportJobViewModel, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ExamSourceApi - factory interface
 * @export
 */
export const ExamSourceApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ExamSourceApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorExamSourceIdentificationTypeGet(xAuth?: string, options?: any): AxiosPromise<void> {
            return localVarFp.administratorExamSourceIdentificationTypeGet(xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {ExamSourceInternalSystemhViewModel} [examSourceInternalSystemhViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorExamSourceInternalSystemPut(xAuth?: string, examSourceInternalSystemhViewModel?: ExamSourceInternalSystemhViewModel, options?: any): AxiosPromise<void> {
            return localVarFp.administratorExamSourceInternalSystemPut(xAuth, examSourceInternalSystemhViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        administratorExamSourceRefreshLastExamPost(xAuth?: string, options?: any): AxiosPromise<void> {
            return localVarFp.administratorExamSourceRefreshLastExamPost(xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} id 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreAdministratorExamSourceIdGet(id: number, xAuth?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCoreAdministratorExamSourceIdGet(id, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject17} inlineObject17 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreAdministratorExamSourcePost(inlineObject17: InlineObject17, xAuth?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCoreAdministratorExamSourcePost(inlineObject17, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [name] 
         * @param {boolean} [occupational] 
         * @param {string} [modifiedStartDate] 
         * @param {string} [modifiedEndDate] 
         * @param {boolean} [isBlocked] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderColumn] 
         * @param {string} [orderBy] 
         * @param {boolean} [internalSystem] 
         * @param {boolean} [hasFinantialAlert] 
         * @param {Array<Notification>} [notifications] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreAdministratorExamSourceSearchGet(name?: string, occupational?: boolean, modifiedStartDate?: string, modifiedEndDate?: string, isBlocked?: boolean, page?: number, pageSize?: number, orderColumn?: string, orderBy?: string, internalSystem?: boolean, hasFinantialAlert?: boolean, notifications?: Array<Notification>, xAuth?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCoreAdministratorExamSourceSearchGet(name, occupational, modifiedStartDate, modifiedEndDate, isBlocked, page, pageSize, orderColumn, orderBy, internalSystem, hasFinantialAlert, notifications, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreExamSourceExamSourceCurrentUserSourcesGet(xAuth?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCoreExamSourceExamSourceCurrentUserSourcesGet(xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [name] 
         * @param {boolean} [occupational] 
         * @param {string} [modifiedStartDate] 
         * @param {string} [modifiedEndDate] 
         * @param {boolean} [isBlocked] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {string} [orderColumn] 
         * @param {string} [orderBy] 
         * @param {boolean} [internalSystem] 
         * @param {boolean} [hasFinantialAlert] 
         * @param {Array<Notification>} [notifications] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreExamSourceExamSourceGetAllGet(name?: string, occupational?: boolean, modifiedStartDate?: string, modifiedEndDate?: string, isBlocked?: boolean, page?: number, pageSize?: number, orderColumn?: string, orderBy?: string, internalSystem?: boolean, hasFinantialAlert?: boolean, notifications?: Array<Notification>, xAuth?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCoreExamSourceExamSourceGetAllGet(name, occupational, modifiedStartDate, modifiedEndDate, isBlocked, page, pageSize, orderColumn, orderBy, internalSystem, hasFinantialAlert, notifications, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} sourceId 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreExamSourceExamSourceLogoGet(sourceId: number, xAuth?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCoreExamSourceExamSourceLogoGet(sourceId, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {SubscriptionViewModel} [subscriptionViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examSourceAddSubscriptionPost(xAuth?: string, subscriptionViewModel?: SubscriptionViewModel, options?: any): AxiosPromise<void> {
            return localVarFp.examSourceAddSubscriptionPost(xAuth, subscriptionViewModel, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examSourceDisplayOptionsGet(xAuth?: string, options?: any): AxiosPromise<DisplayOptionsViewModel> {
            return localVarFp.examSourceDisplayOptionsGet(xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} fonteGuid 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examSourceExamSourceGetLogoExamSourceFonteGuidGet(fonteGuid: string, xAuth?: string, options?: any): AxiosPromise<void> {
            return localVarFp.examSourceExamSourceGetLogoExamSourceFonteGuidGet(fonteGuid, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examSourceExamSourceLogoGet(xAuth?: string, options?: any): AxiosPromise<void> {
            return localVarFp.examSourceExamSourceLogoGet(xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examSourceFinantialAlertGet(xAuth?: string, options?: any): AxiosPromise<FinantialAlertViewModel> {
            return localVarFp.examSourceFinantialAlertGet(xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examSourceMonthlyReportFileGet(month?: number, year?: number, xAuth?: string, options?: any): AxiosPromise<any> {
            return localVarFp.examSourceMonthlyReportFileGet(month, year, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} [month] 
         * @param {number} [year] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examSourceMonthlyReportGet(month?: number, year?: number, xAuth?: string, options?: any): AxiosPromise<void> {
            return localVarFp.examSourceMonthlyReportGet(month, year, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [xAuth] 
         * @param {MonthlyReportJobViewModel} [monthlyReportJobViewModel] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examSourceMonthlyReportStartJobPost(xAuth?: string, monthlyReportJobViewModel?: MonthlyReportJobViewModel, options?: any): AxiosPromise<void> {
            return localVarFp.examSourceMonthlyReportStartJobPost(xAuth, monthlyReportJobViewModel, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for administratorExamSourceIdentificationTypeGet operation in ExamSourceApi.
 * @export
 * @interface ExamSourceApiAdministratorExamSourceIdentificationTypeGetRequest
 */
export interface ExamSourceApiAdministratorExamSourceIdentificationTypeGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ExamSourceApiAdministratorExamSourceIdentificationTypeGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for administratorExamSourceInternalSystemPut operation in ExamSourceApi.
 * @export
 * @interface ExamSourceApiAdministratorExamSourceInternalSystemPutRequest
 */
export interface ExamSourceApiAdministratorExamSourceInternalSystemPutRequest {
    /**
     * 
     * @type {string}
     * @memberof ExamSourceApiAdministratorExamSourceInternalSystemPut
     */
    readonly xAuth?: string

    /**
     * 
     * @type {ExamSourceInternalSystemhViewModel}
     * @memberof ExamSourceApiAdministratorExamSourceInternalSystemPut
     */
    readonly examSourceInternalSystemhViewModel?: ExamSourceInternalSystemhViewModel
}

/**
 * Request parameters for administratorExamSourceRefreshLastExamPost operation in ExamSourceApi.
 * @export
 * @interface ExamSourceApiAdministratorExamSourceRefreshLastExamPostRequest
 */
export interface ExamSourceApiAdministratorExamSourceRefreshLastExamPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ExamSourceApiAdministratorExamSourceRefreshLastExamPost
     */
    readonly xAuth?: string
}

/**
 * Request parameters for apiCoreAdministratorExamSourceIdGet operation in ExamSourceApi.
 * @export
 * @interface ExamSourceApiApiCoreAdministratorExamSourceIdGetRequest
 */
export interface ExamSourceApiApiCoreAdministratorExamSourceIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ExamSourceApiApiCoreAdministratorExamSourceIdGet
     */
    readonly id: number

    /**
     * 
     * @type {string}
     * @memberof ExamSourceApiApiCoreAdministratorExamSourceIdGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for apiCoreAdministratorExamSourcePost operation in ExamSourceApi.
 * @export
 * @interface ExamSourceApiApiCoreAdministratorExamSourcePostRequest
 */
export interface ExamSourceApiApiCoreAdministratorExamSourcePostRequest {
    /**
     * 
     * @type {InlineObject17}
     * @memberof ExamSourceApiApiCoreAdministratorExamSourcePost
     */
    readonly inlineObject17: InlineObject17

    /**
     * 
     * @type {string}
     * @memberof ExamSourceApiApiCoreAdministratorExamSourcePost
     */
    readonly xAuth?: string
}

/**
 * Request parameters for apiCoreAdministratorExamSourceSearchGet operation in ExamSourceApi.
 * @export
 * @interface ExamSourceApiApiCoreAdministratorExamSourceSearchGetRequest
 */
export interface ExamSourceApiApiCoreAdministratorExamSourceSearchGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ExamSourceApiApiCoreAdministratorExamSourceSearchGet
     */
    readonly name?: string

    /**
     * 
     * @type {boolean}
     * @memberof ExamSourceApiApiCoreAdministratorExamSourceSearchGet
     */
    readonly occupational?: boolean

    /**
     * 
     * @type {string}
     * @memberof ExamSourceApiApiCoreAdministratorExamSourceSearchGet
     */
    readonly modifiedStartDate?: string

    /**
     * 
     * @type {string}
     * @memberof ExamSourceApiApiCoreAdministratorExamSourceSearchGet
     */
    readonly modifiedEndDate?: string

    /**
     * 
     * @type {boolean}
     * @memberof ExamSourceApiApiCoreAdministratorExamSourceSearchGet
     */
    readonly isBlocked?: boolean

    /**
     * 
     * @type {number}
     * @memberof ExamSourceApiApiCoreAdministratorExamSourceSearchGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof ExamSourceApiApiCoreAdministratorExamSourceSearchGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof ExamSourceApiApiCoreAdministratorExamSourceSearchGet
     */
    readonly orderColumn?: string

    /**
     * 
     * @type {string}
     * @memberof ExamSourceApiApiCoreAdministratorExamSourceSearchGet
     */
    readonly orderBy?: string

    /**
     * 
     * @type {boolean}
     * @memberof ExamSourceApiApiCoreAdministratorExamSourceSearchGet
     */
    readonly internalSystem?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ExamSourceApiApiCoreAdministratorExamSourceSearchGet
     */
    readonly hasFinantialAlert?: boolean

    /**
     * 
     * @type {Array<Notification>}
     * @memberof ExamSourceApiApiCoreAdministratorExamSourceSearchGet
     */
    readonly notifications?: Array<Notification>

    /**
     * 
     * @type {string}
     * @memberof ExamSourceApiApiCoreAdministratorExamSourceSearchGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for apiCoreExamSourceExamSourceCurrentUserSourcesGet operation in ExamSourceApi.
 * @export
 * @interface ExamSourceApiApiCoreExamSourceExamSourceCurrentUserSourcesGetRequest
 */
export interface ExamSourceApiApiCoreExamSourceExamSourceCurrentUserSourcesGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ExamSourceApiApiCoreExamSourceExamSourceCurrentUserSourcesGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for apiCoreExamSourceExamSourceGetAllGet operation in ExamSourceApi.
 * @export
 * @interface ExamSourceApiApiCoreExamSourceExamSourceGetAllGetRequest
 */
export interface ExamSourceApiApiCoreExamSourceExamSourceGetAllGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ExamSourceApiApiCoreExamSourceExamSourceGetAllGet
     */
    readonly name?: string

    /**
     * 
     * @type {boolean}
     * @memberof ExamSourceApiApiCoreExamSourceExamSourceGetAllGet
     */
    readonly occupational?: boolean

    /**
     * 
     * @type {string}
     * @memberof ExamSourceApiApiCoreExamSourceExamSourceGetAllGet
     */
    readonly modifiedStartDate?: string

    /**
     * 
     * @type {string}
     * @memberof ExamSourceApiApiCoreExamSourceExamSourceGetAllGet
     */
    readonly modifiedEndDate?: string

    /**
     * 
     * @type {boolean}
     * @memberof ExamSourceApiApiCoreExamSourceExamSourceGetAllGet
     */
    readonly isBlocked?: boolean

    /**
     * 
     * @type {number}
     * @memberof ExamSourceApiApiCoreExamSourceExamSourceGetAllGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof ExamSourceApiApiCoreExamSourceExamSourceGetAllGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {string}
     * @memberof ExamSourceApiApiCoreExamSourceExamSourceGetAllGet
     */
    readonly orderColumn?: string

    /**
     * 
     * @type {string}
     * @memberof ExamSourceApiApiCoreExamSourceExamSourceGetAllGet
     */
    readonly orderBy?: string

    /**
     * 
     * @type {boolean}
     * @memberof ExamSourceApiApiCoreExamSourceExamSourceGetAllGet
     */
    readonly internalSystem?: boolean

    /**
     * 
     * @type {boolean}
     * @memberof ExamSourceApiApiCoreExamSourceExamSourceGetAllGet
     */
    readonly hasFinantialAlert?: boolean

    /**
     * 
     * @type {Array<Notification>}
     * @memberof ExamSourceApiApiCoreExamSourceExamSourceGetAllGet
     */
    readonly notifications?: Array<Notification>

    /**
     * 
     * @type {string}
     * @memberof ExamSourceApiApiCoreExamSourceExamSourceGetAllGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for apiCoreExamSourceExamSourceLogoGet operation in ExamSourceApi.
 * @export
 * @interface ExamSourceApiApiCoreExamSourceExamSourceLogoGetRequest
 */
export interface ExamSourceApiApiCoreExamSourceExamSourceLogoGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ExamSourceApiApiCoreExamSourceExamSourceLogoGet
     */
    readonly sourceId: number

    /**
     * 
     * @type {string}
     * @memberof ExamSourceApiApiCoreExamSourceExamSourceLogoGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for examSourceAddSubscriptionPost operation in ExamSourceApi.
 * @export
 * @interface ExamSourceApiExamSourceAddSubscriptionPostRequest
 */
export interface ExamSourceApiExamSourceAddSubscriptionPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ExamSourceApiExamSourceAddSubscriptionPost
     */
    readonly xAuth?: string

    /**
     * 
     * @type {SubscriptionViewModel}
     * @memberof ExamSourceApiExamSourceAddSubscriptionPost
     */
    readonly subscriptionViewModel?: SubscriptionViewModel
}

/**
 * Request parameters for examSourceDisplayOptionsGet operation in ExamSourceApi.
 * @export
 * @interface ExamSourceApiExamSourceDisplayOptionsGetRequest
 */
export interface ExamSourceApiExamSourceDisplayOptionsGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ExamSourceApiExamSourceDisplayOptionsGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for examSourceExamSourceGetLogoExamSourceFonteGuidGet operation in ExamSourceApi.
 * @export
 * @interface ExamSourceApiExamSourceExamSourceGetLogoExamSourceFonteGuidGetRequest
 */
export interface ExamSourceApiExamSourceExamSourceGetLogoExamSourceFonteGuidGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ExamSourceApiExamSourceExamSourceGetLogoExamSourceFonteGuidGet
     */
    readonly fonteGuid: string

    /**
     * 
     * @type {string}
     * @memberof ExamSourceApiExamSourceExamSourceGetLogoExamSourceFonteGuidGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for examSourceExamSourceLogoGet operation in ExamSourceApi.
 * @export
 * @interface ExamSourceApiExamSourceExamSourceLogoGetRequest
 */
export interface ExamSourceApiExamSourceExamSourceLogoGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ExamSourceApiExamSourceExamSourceLogoGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for examSourceFinantialAlertGet operation in ExamSourceApi.
 * @export
 * @interface ExamSourceApiExamSourceFinantialAlertGetRequest
 */
export interface ExamSourceApiExamSourceFinantialAlertGetRequest {
    /**
     * 
     * @type {string}
     * @memberof ExamSourceApiExamSourceFinantialAlertGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for examSourceMonthlyReportFileGet operation in ExamSourceApi.
 * @export
 * @interface ExamSourceApiExamSourceMonthlyReportFileGetRequest
 */
export interface ExamSourceApiExamSourceMonthlyReportFileGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ExamSourceApiExamSourceMonthlyReportFileGet
     */
    readonly month?: number

    /**
     * 
     * @type {number}
     * @memberof ExamSourceApiExamSourceMonthlyReportFileGet
     */
    readonly year?: number

    /**
     * 
     * @type {string}
     * @memberof ExamSourceApiExamSourceMonthlyReportFileGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for examSourceMonthlyReportGet operation in ExamSourceApi.
 * @export
 * @interface ExamSourceApiExamSourceMonthlyReportGetRequest
 */
export interface ExamSourceApiExamSourceMonthlyReportGetRequest {
    /**
     * 
     * @type {number}
     * @memberof ExamSourceApiExamSourceMonthlyReportGet
     */
    readonly month?: number

    /**
     * 
     * @type {number}
     * @memberof ExamSourceApiExamSourceMonthlyReportGet
     */
    readonly year?: number

    /**
     * 
     * @type {string}
     * @memberof ExamSourceApiExamSourceMonthlyReportGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for examSourceMonthlyReportStartJobPost operation in ExamSourceApi.
 * @export
 * @interface ExamSourceApiExamSourceMonthlyReportStartJobPostRequest
 */
export interface ExamSourceApiExamSourceMonthlyReportStartJobPostRequest {
    /**
     * 
     * @type {string}
     * @memberof ExamSourceApiExamSourceMonthlyReportStartJobPost
     */
    readonly xAuth?: string

    /**
     * 
     * @type {MonthlyReportJobViewModel}
     * @memberof ExamSourceApiExamSourceMonthlyReportStartJobPost
     */
    readonly monthlyReportJobViewModel?: MonthlyReportJobViewModel
}

/**
 * ExamSourceApi - object-oriented interface
 * @export
 * @class ExamSourceApi
 * @extends {BaseAPI}
 */
export class ExamSourceApi extends BaseAPI {
    /**
     * 
     * @param {ExamSourceApiAdministratorExamSourceIdentificationTypeGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamSourceApi
     */
    public administratorExamSourceIdentificationTypeGet(requestParameters: ExamSourceApiAdministratorExamSourceIdentificationTypeGetRequest = {}, options?: AxiosRequestConfig) {
        return ExamSourceApiFp(this.configuration).administratorExamSourceIdentificationTypeGet(requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamSourceApiAdministratorExamSourceInternalSystemPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamSourceApi
     */
    public administratorExamSourceInternalSystemPut(requestParameters: ExamSourceApiAdministratorExamSourceInternalSystemPutRequest = {}, options?: AxiosRequestConfig) {
        return ExamSourceApiFp(this.configuration).administratorExamSourceInternalSystemPut(requestParameters.xAuth, requestParameters.examSourceInternalSystemhViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamSourceApiAdministratorExamSourceRefreshLastExamPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamSourceApi
     */
    public administratorExamSourceRefreshLastExamPost(requestParameters: ExamSourceApiAdministratorExamSourceRefreshLastExamPostRequest = {}, options?: AxiosRequestConfig) {
        return ExamSourceApiFp(this.configuration).administratorExamSourceRefreshLastExamPost(requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamSourceApiApiCoreAdministratorExamSourceIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamSourceApi
     */
    public apiCoreAdministratorExamSourceIdGet(requestParameters: ExamSourceApiApiCoreAdministratorExamSourceIdGetRequest, options?: AxiosRequestConfig) {
        return ExamSourceApiFp(this.configuration).apiCoreAdministratorExamSourceIdGet(requestParameters.id, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamSourceApiApiCoreAdministratorExamSourcePostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamSourceApi
     */
    public apiCoreAdministratorExamSourcePost(requestParameters: ExamSourceApiApiCoreAdministratorExamSourcePostRequest, options?: AxiosRequestConfig) {
        return ExamSourceApiFp(this.configuration).apiCoreAdministratorExamSourcePost(requestParameters.inlineObject17, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamSourceApiApiCoreAdministratorExamSourceSearchGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamSourceApi
     */
    public apiCoreAdministratorExamSourceSearchGet(requestParameters: ExamSourceApiApiCoreAdministratorExamSourceSearchGetRequest = {}, options?: AxiosRequestConfig) {
        return ExamSourceApiFp(this.configuration).apiCoreAdministratorExamSourceSearchGet(requestParameters.name, requestParameters.occupational, requestParameters.modifiedStartDate, requestParameters.modifiedEndDate, requestParameters.isBlocked, requestParameters.page, requestParameters.pageSize, requestParameters.orderColumn, requestParameters.orderBy, requestParameters.internalSystem, requestParameters.hasFinantialAlert, requestParameters.notifications, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamSourceApiApiCoreExamSourceExamSourceCurrentUserSourcesGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamSourceApi
     */
    public apiCoreExamSourceExamSourceCurrentUserSourcesGet(requestParameters: ExamSourceApiApiCoreExamSourceExamSourceCurrentUserSourcesGetRequest = {}, options?: AxiosRequestConfig) {
        return ExamSourceApiFp(this.configuration).apiCoreExamSourceExamSourceCurrentUserSourcesGet(requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamSourceApiApiCoreExamSourceExamSourceGetAllGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamSourceApi
     */
    public apiCoreExamSourceExamSourceGetAllGet(requestParameters: ExamSourceApiApiCoreExamSourceExamSourceGetAllGetRequest = {}, options?: AxiosRequestConfig) {
        return ExamSourceApiFp(this.configuration).apiCoreExamSourceExamSourceGetAllGet(requestParameters.name, requestParameters.occupational, requestParameters.modifiedStartDate, requestParameters.modifiedEndDate, requestParameters.isBlocked, requestParameters.page, requestParameters.pageSize, requestParameters.orderColumn, requestParameters.orderBy, requestParameters.internalSystem, requestParameters.hasFinantialAlert, requestParameters.notifications, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamSourceApiApiCoreExamSourceExamSourceLogoGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamSourceApi
     */
    public apiCoreExamSourceExamSourceLogoGet(requestParameters: ExamSourceApiApiCoreExamSourceExamSourceLogoGetRequest, options?: AxiosRequestConfig) {
        return ExamSourceApiFp(this.configuration).apiCoreExamSourceExamSourceLogoGet(requestParameters.sourceId, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamSourceApiExamSourceAddSubscriptionPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamSourceApi
     */
    public examSourceAddSubscriptionPost(requestParameters: ExamSourceApiExamSourceAddSubscriptionPostRequest = {}, options?: AxiosRequestConfig) {
        return ExamSourceApiFp(this.configuration).examSourceAddSubscriptionPost(requestParameters.xAuth, requestParameters.subscriptionViewModel, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamSourceApiExamSourceDisplayOptionsGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamSourceApi
     */
    public examSourceDisplayOptionsGet(requestParameters: ExamSourceApiExamSourceDisplayOptionsGetRequest = {}, options?: AxiosRequestConfig) {
        return ExamSourceApiFp(this.configuration).examSourceDisplayOptionsGet(requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamSourceApiExamSourceExamSourceGetLogoExamSourceFonteGuidGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamSourceApi
     */
    public examSourceExamSourceGetLogoExamSourceFonteGuidGet(requestParameters: ExamSourceApiExamSourceExamSourceGetLogoExamSourceFonteGuidGetRequest, options?: AxiosRequestConfig) {
        return ExamSourceApiFp(this.configuration).examSourceExamSourceGetLogoExamSourceFonteGuidGet(requestParameters.fonteGuid, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamSourceApiExamSourceExamSourceLogoGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamSourceApi
     */
    public examSourceExamSourceLogoGet(requestParameters: ExamSourceApiExamSourceExamSourceLogoGetRequest = {}, options?: AxiosRequestConfig) {
        return ExamSourceApiFp(this.configuration).examSourceExamSourceLogoGet(requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamSourceApiExamSourceFinantialAlertGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamSourceApi
     */
    public examSourceFinantialAlertGet(requestParameters: ExamSourceApiExamSourceFinantialAlertGetRequest = {}, options?: AxiosRequestConfig) {
        return ExamSourceApiFp(this.configuration).examSourceFinantialAlertGet(requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamSourceApiExamSourceMonthlyReportFileGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamSourceApi
     */
    public examSourceMonthlyReportFileGet(requestParameters: ExamSourceApiExamSourceMonthlyReportFileGetRequest = {}, options?: AxiosRequestConfig) {
        return ExamSourceApiFp(this.configuration).examSourceMonthlyReportFileGet(requestParameters.month, requestParameters.year, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamSourceApiExamSourceMonthlyReportGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamSourceApi
     */
    public examSourceMonthlyReportGet(requestParameters: ExamSourceApiExamSourceMonthlyReportGetRequest = {}, options?: AxiosRequestConfig) {
        return ExamSourceApiFp(this.configuration).examSourceMonthlyReportGet(requestParameters.month, requestParameters.year, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {ExamSourceApiExamSourceMonthlyReportStartJobPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ExamSourceApi
     */
    public examSourceMonthlyReportStartJobPost(requestParameters: ExamSourceApiExamSourceMonthlyReportStartJobPostRequest = {}, options?: AxiosRequestConfig) {
        return ExamSourceApiFp(this.configuration).examSourceMonthlyReportStartJobPost(requestParameters.xAuth, requestParameters.monthlyReportJobViewModel, options).then((request) => request(this.axios, this.basePath));
    }
}
