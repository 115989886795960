import { defineStore } from 'pinia'
import { ref } from 'vue'
import { SpecialityApi } from '@/api-client'
import axiosInstance from '@/api/axios'
import { useToast } from 'vue-toastification'

export const useSpecialityStore = defineStore(
  'speciality',
  () => {
    const items = ref([])
    const itemsCurrent = ref([])
    const toast = useToast()

    async function getAll() {
      try {
        const specialityApi = new SpecialityApi(undefined, undefined, axiosInstance)
        const response = await specialityApi.apiCoreExamSourceSpecialityGet()
        items.value = response.data.data
      } catch (err) {
        console.error('Erro ao processar requisição:', err)
        toast.error('Erro ao carregar especialidades', {
          timeout: 3000,
        })
      }
    }

    async function getFromExam(examId) {
      try {
        const response = await axiosInstance.get(`/api/core/Administrator/PhysicianSpecialities`, {
          params: {
            objectId: examId,
          },
        })
        if (!response || response.status !== 200) {
          throw new Error('Erro ao buscar especialidades do médico.')
        }
        itemsCurrent.value = response.data.data
        return response.data
      } catch (err) {
        console.error('Erro ao buscar especialidades do médico:', err.message)
        toast.error('Erro ao buscar especialidades do médico.', { timeout: 3000 })
      }
    }

    return {
      items,
      itemsCurrent,
      getAll,
      getFromExam,
    }
  },
  {
    persist: [
      {
        paths: ['items'],
        storage: localStorage,
      },
    ],
  },
)
