/* tslint:disable */
/* eslint-disable */
/**
 * Portal Telemedicina API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { InlineObject18 } from '../models';
// @ts-ignore
import { InlineObject19 } from '../models';
// @ts-ignore
import { MaskSpecialityViewModel } from '../models';
// @ts-ignore
import { MaskViewModel } from '../models';
// @ts-ignore
import { Notification } from '../models';
/**
 * MaskApi - axios parameter creator
 * @export
 */
export const MaskApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {InlineObject18} inlineObject18 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreMaskCreateMaskPost: async (inlineObject18: InlineObject18, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject18' is not null or undefined
            assertParamExists('apiCoreMaskCreateMaskPost', 'inlineObject18', inlineObject18)
            const localVarPath = `/api/core/Mask/CreateMask`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject18, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {string} [name] 
         * @param {number} [specialityId] 
         * @param {number} [maskId] 
         * @param {boolean} [isChanged] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {number} [languageId] 
         * @param {Array<Notification>} [notifications] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreMaskGetMasksGet: async (name?: string, specialityId?: number, maskId?: number, isChanged?: boolean, page?: number, pageSize?: number, languageId?: number, notifications?: Array<Notification>, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/core/Mask/GetMasks`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (name !== undefined) {
                localVarQueryParameter['Name'] = name;
            }

            if (specialityId !== undefined) {
                localVarQueryParameter['SpecialityId'] = specialityId;
            }

            if (maskId !== undefined) {
                localVarQueryParameter['MaskId'] = maskId;
            }

            if (isChanged !== undefined) {
                localVarQueryParameter['IsChanged'] = isChanged;
            }

            if (page !== undefined) {
                localVarQueryParameter['Page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['PageSize'] = pageSize;
            }

            if (languageId !== undefined) {
                localVarQueryParameter['LanguageId'] = languageId;
            }

            if (notifications) {
                localVarQueryParameter['Notifications'] = notifications;
            }

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {InlineObject19} inlineObject19 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreMaskUpdateMaskPut: async (inlineObject19: InlineObject19, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'inlineObject19' is not null or undefined
            assertParamExists('apiCoreMaskUpdateMaskPut', 'inlineObject19', inlineObject19)
            const localVarPath = `/api/core/Mask/UpdateMask`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(inlineObject19, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} specialityId 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCorePhysicianMaskSpecialityIdGet: async (specialityId: number, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'specialityId' is not null or undefined
            assertParamExists('apiCorePhysicianMaskSpecialityIdGet', 'specialityId', specialityId)
            const localVarPath = `/api/core/Physician/Mask/{specialityId}`
                .replace(`{${"specialityId"}}`, encodeURIComponent(String(specialityId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * MaskApi - functional programming interface
 * @export
 */
export const MaskApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = MaskApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {InlineObject18} inlineObject18 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoreMaskCreateMaskPost(inlineObject18: InlineObject18, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoreMaskCreateMaskPost(inlineObject18, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {string} [name] 
         * @param {number} [specialityId] 
         * @param {number} [maskId] 
         * @param {boolean} [isChanged] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {number} [languageId] 
         * @param {Array<Notification>} [notifications] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoreMaskGetMasksGet(name?: string, specialityId?: number, maskId?: number, isChanged?: boolean, page?: number, pageSize?: number, languageId?: number, notifications?: Array<Notification>, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaskSpecialityViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoreMaskGetMasksGet(name, specialityId, maskId, isChanged, page, pageSize, languageId, notifications, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {InlineObject19} inlineObject19 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoreMaskUpdateMaskPut(inlineObject19: InlineObject19, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoreMaskUpdateMaskPut(inlineObject19, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} specialityId 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCorePhysicianMaskSpecialityIdGet(specialityId: number, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MaskViewModel>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCorePhysicianMaskSpecialityIdGet(specialityId, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * MaskApi - factory interface
 * @export
 */
export const MaskApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = MaskApiFp(configuration)
    return {
        /**
         * 
         * @param {InlineObject18} inlineObject18 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreMaskCreateMaskPost(inlineObject18: InlineObject18, xAuth?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCoreMaskCreateMaskPost(inlineObject18, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {string} [name] 
         * @param {number} [specialityId] 
         * @param {number} [maskId] 
         * @param {boolean} [isChanged] 
         * @param {number} [page] 
         * @param {number} [pageSize] 
         * @param {number} [languageId] 
         * @param {Array<Notification>} [notifications] 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreMaskGetMasksGet(name?: string, specialityId?: number, maskId?: number, isChanged?: boolean, page?: number, pageSize?: number, languageId?: number, notifications?: Array<Notification>, xAuth?: string, options?: any): AxiosPromise<MaskSpecialityViewModel> {
            return localVarFp.apiCoreMaskGetMasksGet(name, specialityId, maskId, isChanged, page, pageSize, languageId, notifications, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {InlineObject19} inlineObject19 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreMaskUpdateMaskPut(inlineObject19: InlineObject19, xAuth?: string, options?: any): AxiosPromise<void> {
            return localVarFp.apiCoreMaskUpdateMaskPut(inlineObject19, xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} specialityId 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCorePhysicianMaskSpecialityIdGet(specialityId: number, xAuth?: string, options?: any): AxiosPromise<MaskViewModel> {
            return localVarFp.apiCorePhysicianMaskSpecialityIdGet(specialityId, xAuth, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiCoreMaskCreateMaskPost operation in MaskApi.
 * @export
 * @interface MaskApiApiCoreMaskCreateMaskPostRequest
 */
export interface MaskApiApiCoreMaskCreateMaskPostRequest {
    /**
     * 
     * @type {InlineObject18}
     * @memberof MaskApiApiCoreMaskCreateMaskPost
     */
    readonly inlineObject18: InlineObject18

    /**
     * 
     * @type {string}
     * @memberof MaskApiApiCoreMaskCreateMaskPost
     */
    readonly xAuth?: string
}

/**
 * Request parameters for apiCoreMaskGetMasksGet operation in MaskApi.
 * @export
 * @interface MaskApiApiCoreMaskGetMasksGetRequest
 */
export interface MaskApiApiCoreMaskGetMasksGetRequest {
    /**
     * 
     * @type {string}
     * @memberof MaskApiApiCoreMaskGetMasksGet
     */
    readonly name?: string

    /**
     * 
     * @type {number}
     * @memberof MaskApiApiCoreMaskGetMasksGet
     */
    readonly specialityId?: number

    /**
     * 
     * @type {number}
     * @memberof MaskApiApiCoreMaskGetMasksGet
     */
    readonly maskId?: number

    /**
     * 
     * @type {boolean}
     * @memberof MaskApiApiCoreMaskGetMasksGet
     */
    readonly isChanged?: boolean

    /**
     * 
     * @type {number}
     * @memberof MaskApiApiCoreMaskGetMasksGet
     */
    readonly page?: number

    /**
     * 
     * @type {number}
     * @memberof MaskApiApiCoreMaskGetMasksGet
     */
    readonly pageSize?: number

    /**
     * 
     * @type {number}
     * @memberof MaskApiApiCoreMaskGetMasksGet
     */
    readonly languageId?: number

    /**
     * 
     * @type {Array<Notification>}
     * @memberof MaskApiApiCoreMaskGetMasksGet
     */
    readonly notifications?: Array<Notification>

    /**
     * 
     * @type {string}
     * @memberof MaskApiApiCoreMaskGetMasksGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for apiCoreMaskUpdateMaskPut operation in MaskApi.
 * @export
 * @interface MaskApiApiCoreMaskUpdateMaskPutRequest
 */
export interface MaskApiApiCoreMaskUpdateMaskPutRequest {
    /**
     * 
     * @type {InlineObject19}
     * @memberof MaskApiApiCoreMaskUpdateMaskPut
     */
    readonly inlineObject19: InlineObject19

    /**
     * 
     * @type {string}
     * @memberof MaskApiApiCoreMaskUpdateMaskPut
     */
    readonly xAuth?: string
}

/**
 * Request parameters for apiCorePhysicianMaskSpecialityIdGet operation in MaskApi.
 * @export
 * @interface MaskApiApiCorePhysicianMaskSpecialityIdGetRequest
 */
export interface MaskApiApiCorePhysicianMaskSpecialityIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof MaskApiApiCorePhysicianMaskSpecialityIdGet
     */
    readonly specialityId: number

    /**
     * 
     * @type {string}
     * @memberof MaskApiApiCorePhysicianMaskSpecialityIdGet
     */
    readonly xAuth?: string
}

/**
 * MaskApi - object-oriented interface
 * @export
 * @class MaskApi
 * @extends {BaseAPI}
 */
export class MaskApi extends BaseAPI {
    /**
     * 
     * @param {MaskApiApiCoreMaskCreateMaskPostRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaskApi
     */
    public apiCoreMaskCreateMaskPost(requestParameters: MaskApiApiCoreMaskCreateMaskPostRequest, options?: AxiosRequestConfig) {
        return MaskApiFp(this.configuration).apiCoreMaskCreateMaskPost(requestParameters.inlineObject18, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MaskApiApiCoreMaskGetMasksGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaskApi
     */
    public apiCoreMaskGetMasksGet(requestParameters: MaskApiApiCoreMaskGetMasksGetRequest = {}, options?: AxiosRequestConfig) {
        return MaskApiFp(this.configuration).apiCoreMaskGetMasksGet(requestParameters.name, requestParameters.specialityId, requestParameters.maskId, requestParameters.isChanged, requestParameters.page, requestParameters.pageSize, requestParameters.languageId, requestParameters.notifications, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MaskApiApiCoreMaskUpdateMaskPutRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaskApi
     */
    public apiCoreMaskUpdateMaskPut(requestParameters: MaskApiApiCoreMaskUpdateMaskPutRequest, options?: AxiosRequestConfig) {
        return MaskApiFp(this.configuration).apiCoreMaskUpdateMaskPut(requestParameters.inlineObject19, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {MaskApiApiCorePhysicianMaskSpecialityIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof MaskApi
     */
    public apiCorePhysicianMaskSpecialityIdGet(requestParameters: MaskApiApiCorePhysicianMaskSpecialityIdGetRequest, options?: AxiosRequestConfig) {
        return MaskApiFp(this.configuration).apiCorePhysicianMaskSpecialityIdGet(requestParameters.specialityId, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }
}
