/* tslint:disable */
/* eslint-disable */
/**
 * Portal Telemedicina API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v2
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { SpecialityViewModel } from '../models';
/**
 * SpecialityApi - axios parameter creator
 * @export
 */
export const SpecialityApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreExamSourceSpecialityGet: async (xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/core/ExamSource/Speciality`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examSourceSpecialityCategoryIdGet: async (categoryId: number, xAuth?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('examSourceSpecialityCategoryIdGet', 'categoryId', categoryId)
            const localVarPath = `/ExamSource/Speciality/{categoryId}`
                .replace(`{${"categoryId"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication Bearer required
            await setApiKeyToObject(localVarHeaderParameter, "Authorization", configuration)

            if (xAuth !== undefined && xAuth !== null) {
                localVarHeaderParameter['X-Auth'] = String(xAuth);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SpecialityApi - functional programming interface
 * @export
 */
export const SpecialityApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SpecialityApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async apiCoreExamSourceSpecialityGet(xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SpecialityViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.apiCoreExamSourceSpecialityGet(xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async examSourceSpecialityCategoryIdGet(categoryId: number, xAuth?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<SpecialityViewModel>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.examSourceSpecialityCategoryIdGet(categoryId, xAuth, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * SpecialityApi - factory interface
 * @export
 */
export const SpecialityApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SpecialityApiFp(configuration)
    return {
        /**
         * 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        apiCoreExamSourceSpecialityGet(xAuth?: string, options?: any): AxiosPromise<Array<SpecialityViewModel>> {
            return localVarFp.apiCoreExamSourceSpecialityGet(xAuth, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @param {number} categoryId 
         * @param {string} [xAuth] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        examSourceSpecialityCategoryIdGet(categoryId: number, xAuth?: string, options?: any): AxiosPromise<Array<SpecialityViewModel>> {
            return localVarFp.examSourceSpecialityCategoryIdGet(categoryId, xAuth, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * Request parameters for apiCoreExamSourceSpecialityGet operation in SpecialityApi.
 * @export
 * @interface SpecialityApiApiCoreExamSourceSpecialityGetRequest
 */
export interface SpecialityApiApiCoreExamSourceSpecialityGetRequest {
    /**
     * 
     * @type {string}
     * @memberof SpecialityApiApiCoreExamSourceSpecialityGet
     */
    readonly xAuth?: string
}

/**
 * Request parameters for examSourceSpecialityCategoryIdGet operation in SpecialityApi.
 * @export
 * @interface SpecialityApiExamSourceSpecialityCategoryIdGetRequest
 */
export interface SpecialityApiExamSourceSpecialityCategoryIdGetRequest {
    /**
     * 
     * @type {number}
     * @memberof SpecialityApiExamSourceSpecialityCategoryIdGet
     */
    readonly categoryId: number

    /**
     * 
     * @type {string}
     * @memberof SpecialityApiExamSourceSpecialityCategoryIdGet
     */
    readonly xAuth?: string
}

/**
 * SpecialityApi - object-oriented interface
 * @export
 * @class SpecialityApi
 * @extends {BaseAPI}
 */
export class SpecialityApi extends BaseAPI {
    /**
     * 
     * @param {SpecialityApiApiCoreExamSourceSpecialityGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpecialityApi
     */
    public apiCoreExamSourceSpecialityGet(requestParameters: SpecialityApiApiCoreExamSourceSpecialityGetRequest = {}, options?: AxiosRequestConfig) {
        return SpecialityApiFp(this.configuration).apiCoreExamSourceSpecialityGet(requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @param {SpecialityApiExamSourceSpecialityCategoryIdGetRequest} requestParameters Request parameters.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SpecialityApi
     */
    public examSourceSpecialityCategoryIdGet(requestParameters: SpecialityApiExamSourceSpecialityCategoryIdGetRequest, options?: AxiosRequestConfig) {
        return SpecialityApiFp(this.configuration).examSourceSpecialityCategoryIdGet(requestParameters.categoryId, requestParameters.xAuth, options).then((request) => request(this.axios, this.basePath));
    }
}
