import { App, computed, reactive, readonly, ref, inject } from 'vue'
import { setupDevtools } from './devtools'
import { configureAuthorizationHeaderInterceptor } from './interceptors'
import { configureNavigationGuards } from './navigationGuards'
import { ANONYMOUS_USER, AuthOptions, AuthPlugin, RequiredAuthOptions, User } from './types'
import { useLoggedUserStore } from '@/stores/loggedUser'
import { useToast } from 'vue-toastification'
import { SOCKET_KEY } from '@/plugins/socketPlugin'

export let authInstance: AuthPlugin | undefined = undefined

function setupAuthPlugin(options: RequiredAuthOptions): AuthPlugin {
  const toast = useToast()
  const router = options.router
  const accessToken = ref<string>()
  const user = ref<User>({ ...ANONYMOUS_USER })
  const userFullName = computed(() => {
    let fullname = user.value.firstName
    if (user.value.lastName) {
      fullname += ` ${user.value.lastName}`
    }
    return fullname
  })

  const isAuthenticated = computed(() => {
    if (!user.value?.expires) return false
    const expireDate = new Date(user.value.expires)
    return expireDate > new Date()
  })

  // Restore user session from localStorage if available
  const loggedUserStore = useLoggedUserStore()
  if (loggedUserStore.item) {
    user.value = loggedUserStore.item
    if (user.value) {
      accessToken.value = user.value.token
    } else {
      accessToken.value = undefined
    }
  }

  // const app_env = ref(import.meta.env.VITE_APP_ENV)
  async function login({ username, password }: { username: string; password: string }) {
    try {
      await loggedUserStore.authenticate({ username, password })
      user.value = loggedUserStore.item
      // const userRole = (user.value.roles && user.value.roles[0]) || 'visitor'

      accessToken.value = user.value.token

      if (undefined == loggedUserStore.currentSource) {
        loggedUserStore.currentSource =
          loggedUserStore.item.source.length > 0 ? loggedUserStore.item.source[0].id : undefined
      }

      const redirectTo = (() => {
        if (user.value.roles && user.value.roles.includes('physician')) {
          const { onlyReports, listExams } = user.value.physician || {}

          if (!onlyReports && !listExams) {
            toast.warning('Você não possui nenhuma função habilitada', { timeout: 9000 })
            logout()
            return '/login'
          }
          console.log('onlyReports:', onlyReports, 'listExams:', listExams)
          if (onlyReports && !listExams) {
            console.log('Redirecionando para relatórios apenas.')
            return '/exams-report-medico' // Redirecionar para relatórios apenas.
          }

          if (listExams) {
            console.log('Redirecionando para lista de exames.')
            return '/radio-report-medico' // Redirecionar para lista de exames.
          }

          // Caso nenhuma das condições seja atendida.
          toast.error('Erro de configuração. Entre em contato com o suporte.', { timeout: 9000 })
          logout()
          return '/login'
        }

        if (user.value.roles && user.value.roles.includes('analyst')) {
          return '/analyst'
        }

        if (user.value.roles && user.value.roles.includes('admin')) {
          return '/admin'
        }

        // Padrão para outras funções.
        return '/operational'
      })()

      await router.push(redirectTo)
      // router.push(router.currentRoute.value.query.redirectTo?.toString() || redirectTo)

      toast.success('Autenticado com sucesso', {
        timeout: 3000,
      })
    } catch (error) {
      console.log(error)
      throw new Error(error)
    }
  }

  async function logout() {
    try {
      // const socket = inject(SOCKET_KEY)
      // if (socket) {
      //   socket.emit('force-disconnect', user)
      // }

      // Limpa os dados do usuário
      user.value = { ...ANONYMOUS_USER }
      accessToken.value = undefined

      // Limpa todos os dados armazenados
      localStorage.clear()
      sessionStorage.clear()

      // Redireciona para a página inicial
      await router.push({ name: 'index' })
    } catch (error) {
      console.error('Erro ao fazer logout:', error)
      toast.error('Erro ao fazer logout', {
        timeout: 3000,
      })
    }
  }

  /*
   * "reactive" unwraps 'ref's, therefore using the .value is not required.
   * E.g: from "auth.isAuthenticated.value" to "auth.isAuthenticated"
   * but when using destructuring like: { isAuthenticated } = useAuth() the reactivity over isAuthenticated would be lost
   * this is not recommended but in such case use toRefs: { isAuthenticated } = toRefs(useAuth())
   * See: https://v3.vuejs.org/guide/reactivity-fundamentals.html#ref-unwrapping
   * And: https://v3.vuejs.org/guide/reactivity-fundamentals.html#destructuring-reactive-state
   */
  const unWrappedRefs = reactive({
    isAuthenticated,
    accessToken,
    user,
    userFullName,
    login,
    logout,
  })

  return readonly(unWrappedRefs)
}

const defaultOptions = {
  logoutRedirectRoute: '/',
  loginRouteName: 'index',
  autoConfigureNavigationGuards: true,
}

export function createAuth(appOptions: AuthOptions) {
  // Fill default values to options that were not received
  const options: RequiredAuthOptions = { ...defaultOptions, ...appOptions }

  return {
    install: (app: App): void => {
      authInstance = setupAuthPlugin(options)
      app.config.globalProperties.$auth = authInstance

      if (options.autoConfigureNavigationGuards) {
        configureNavigationGuards(options.router, options)
      }

      if (options.axios?.autoAddAuthorizationHeader) {
        configureAuthorizationHeaderInterceptor(options.axios.instance, options.axios.authorizationHeaderPrefix)
      }

      if (import.meta.env.DEV) {
        // until it gets fixed in devtools
        setupDevtools(app, authInstance)
      }
    },
  }
}
